@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Eurostile', sans-serif;
}

button {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.header-gradient {
  background: linear-gradient(90.33deg, rgba(11, 17, 19, 0.7) 7.86%, rgba(0, 0, 0, 0) 92.16%);
}

@import 'internal-ads.scss';
@import 'footer';
@import 'blog';
@import 'utilities';
@import 'cms'; /* Styles for standard basic pages, i.e. terms */

.basicInput::placeholder {
  color: #7f898d;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
}

#nprogress .spinner {
  display: none !important;
}

.product-gallery-section {
  .control-dots,
  .carousel-status {
    display: none;
  }

  .thumbs {
    text-align: center;
    display: flex;
  }

  .carousel .thumbs-wrapper {
    margin: 20px 0 !important;
  }

  .carousel .thumb {
    width: 75px;
    height: 48px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    border: 3px solid #7f898d;
    padding: 0;
    border-width: 1px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 1px solid #f5f5f5;
    opacity: 1;
    transition: all 0.3s ease-in;
  }

  .carousel.carousel-slider .control-arrow {
    padding: 10px;
    opacity: 0.8;
  }
  .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    content: '';
  }
}

.gallery-3d {
  .carousel .thumb {
    width: 140px !important;
    height: 80px !important;
    img {
      min-width: 140px !important;
    }
  }
}

.responsive-product-grid {
  display: grid;
  // grid-gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (min-width: 350px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  @media (min-width: 800px) {
    grid-template-columns: repeat(2, minmax(230px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(215px, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: 3440px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.slick-slider {
  .slick-dots {
    // left: -55px;
    z-index: 0;
    bottom: -30px;
    list-style-type: none;

    li {
      width: 7px !important;
      height: 7px !important;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);

      button {
        width: 100% !important;
        height: 100% !important;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;

        &:before {
          content: none !important;
        }

        &:hover,
        &:focus {
          outline: none;
          background-color: rgb(205, 205, 205);
          border-radius: 50%;
        }
      }
    }

    li.slick-active {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}

.collectionCarouselContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.collectionCarouselContent {
  width: 100%;
  height: 100%;
}

.relatedCollectionCarousel {
  position: relative;
  // width: 75%;
  height: 100%;
  overflow: none;
}

.leftArrowCollection,
.rightArrowCollection {
  position: absolute;
  // top: 35%;
  transform: translateY(-50%);
  z-index: 0;
  cursor: pointer;
  // opacity: 0.5;
  font-size: 2rem;
  color: white;
}

.leftArrowCollection:hover,
.rightArrowCollection:hover {
  opacity: 1;
}

.leftArrowCollection {
  left: -55px;
}

.rightArrowCollection {
  right: -50px;
}

.custom-rounded-box {
  border-radius: 5px;
  flex-shrink: 0;
  background-size: cover;
  margin-right: 1rem;
  filter: none;
  width: 290px;
  height: 163.13px;
}

@media (min-width: 320px) {
  .custom-rounded-box {
    width: 250px;
    height: 140.63px;
  }
}

@media (min-width: 768px) {
  .custom-rounded-box {
    width: 240px;
    height: 135px;
  }
}

@media (min-width: 1024px) {
  .custom-rounded-box {
    width: 260px;
    height: 163.13px;
  }
}

@media (min-width: 1280px) {
  .custom-rounded-box {
    width: 245px;
    height: 137.81px;
  }
}

@media (min-width: 1536px) {
  .custom-rounded-box {
    width: 295px;
    height: 165.94px;
  }
}

@media (min-width: 1920px) {
  .custom-rounded-box {
    width: 355px;
    height: 199.69px;
  }
}

@media (min-width: 2000px) {
  .custom-rounded-box {
    min-width: 435px;
    min-height: 244.69px;
  }
}
@media (min-width: 3440px) {
  .custom-rounded-box {
    min-width: 635px;
    min-height: 357.19px;
  }
}

@media screen and (max-width: 1600px) {
  .wrap-after::after {
    content: '\A';
    white-space: pre;
  }
}

.video-overlay-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

div.grecaptcha-badge {
  display: none !important;
}

div.woot--bubble-holder {
  position: fixed !important;
  z-index: 0 !important;
}

button#gpt-trainer-window-button {
  z-index: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #15191b;
  box-shadow: inset 0 0 5px #252b2d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #363839;
  border-radius: 9999px; /* rounded */
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #505c60;
}

.scrollbar-custom {
  overflow-y: auto !important;
  cursor: pointer;
}

.pfl-scrollbar-custom {
  height: 200px;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 4px;
}
.scrollbar-custom::-webkit-scrollbar-track {
  background: #15191b;
}
.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #505c60;
  border-radius: 9999px; /* rounded */
}
.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #7f898d;
}

.scrollable-div {
  overflow-y: auto;
  transition: all 0.3s;
}

.scrollable-div::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar by default */
}

.scrollable-div:hover::-webkit-scrollbar {
  width: 4px; /* Show scrollbar on hover */
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.custom-carousel {
  .thumbnail-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    align-items: center;
    padding: 0 10px;
  }

  .thumbnail-item {
    flex: 0 0 auto;
    height: 45px;
    aspect-ratio: 16 / 9;
  }

  .thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

input.no-arrows-number-input::-webkit-outer-spin-button,
input.no-arrows-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-arrows-number-input {
  -moz-appearance: textfield;
}
