.blog-internal-ads {
  .slick-dots {
    display: none;
  }
}

.focused-article {
  h2 {
    transition: 0.3s;
    color: #28a0db;
  }
  div > p {
    transition: 0.3s;
    color: #f5f5f5;
  }

  .bg-image {
    transition: 0.3s;
    transform: scale(1.2);
  }
}

.blog-header-img {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.blog-header-gradient {
  position: absolute;
  width: 741px;
  height: 662px;
  right: 0;
  top: 0;
  background: linear-gradient(270.04deg, rgba(11, 17, 19, 0.9) 47.98%, rgba(11, 17, 19, 0) 99.97%);
}

.post {
  p {
    margin: 1.25em 0;
  }
  
  .content {
    line-height: 1.8;
    font-size: 1.1em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
    }

    h1 {
      font-size: 2.2em;
      line-height: 1;
      margin-top: 0.5em;
      margin-bottom: 0.25em;
    }

    h2 {
      font-size: 1.6em;
      line-height: 1.4;
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    h3 {
      font-size: 1.3em;
      line-height: 1;
      margin-top: 0.5em;
      margin-bottom: 0.25em;
      margin-bottom: 0.5em;
    }

    h4 {
      font-size: 1.1em;
      line-height: 1.25;
      margin-top: 0.5em;
      margin-bottom: 0.25em;
    }

    h5 {
      font-size: 1em;
      margin-bottom: 0.25em;
    }

    h6 {
      font-size: 1em;
      margin-bottom: 0.25em;
    }

    ul,
    ol,
    dl {
      padding-left: 25px;
      list-style: normal;
    }

    ul li {
      margin: 6px 0;
      list-style-type: disc;
    }

    .subtitle {
      font-family: 'Inter', sans-serif;
      margin-bottom: 14px;
    }

    .fi-check {
      &:before {
        border-radius: 50%;
        background: #43ac6a;
        color: #fff;
        box-sizing: border-box;
        padding: 0.15em 0.25em;
      }
    }

    a {
      color: #00a1e0;
      &:hover {
        border-bottom: 1px dotted;
      }
    }

    img {
      width: 100%;
    }

    figure {
      margin: 1em 0;

      .slider-show {
        display: none;
      }

      figcaption {
        font-size: 0.75em;
        color: #666;
      }

      &[data-trix-content-type='image'] {
        padding-bottom: 0;
        height: auto;
      }

      &:not([data-trix-content-type='image']) {
        @extend .flex-video !optional;
        overflow: hidden;
        padding-bottom: 56.25%;

        &[data-trix-attributes*='caption'] {
          margin-bottom: 2.1rem;

          figcaption.caption {
            position: absolute;
            bottom: 0;
            margin-bottom: -1.6rem;
          }
        }
      }

      &[data-trix-content-type='slider'] {
        overflow: visible;
        height: auto;
        padding: 10px;
      }

      &[data-trix-attachment*='image'] {
        padding-bottom: 0;
        height: auto;
      }
    }
  }
}

.tutorials {

  h1 {
      font-size: 1.8em;
      line-height: 1;
      margin-bottom: 0.25em;
    }

  h2 {
      font-size: 1.6em;
      line-height: 1.4;
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    h3 {
      font-size: 1.3em;
      line-height: 1;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    h4 {
      font-size: 1.1em;
      line-height: 1.25;
      margin-top: 0.5em;
      margin-bottom: 1em;
    }


    h5 {
      font-size: 1em;
      margin-bottom: 0.25em;
    }

    h6 {
      font-size: 1em;
      margin-bottom: 0.25em;
    }

    ul,
    ol,
    dl {
      padding-left: 25px;
      list-style: normal;
      margin-bottom: 3em;
    }

    ul li {
      margin: 6px 0;
      list-style-type: disc;
    }

    p {
      margin-bottom: 3em;
    }

   .subtitle {
      font-family: 'Inter', sans-serif;
      margin-bottom: 14px;
    }

    .fi-check {
      &:before {
        border-radius: 50%;
        background: #43ac6a;
        color: #fff;
        box-sizing: border-box;
        padding: 0.15em 0.25em;
      }
    }

    a {
      color: #00a1e0;
      &:hover {
        border-bottom: 1px dotted;
      }
    }

    strong {
      color: #00a1e0;
    }

    img {
      width: 100%;
    }
}
