.ads-container.slick-slider {
  margin-bottom: 0;
}

.ads-container {
  color: #f5f5f5;

  .internal-ad-container {
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 224px;

    .internal-ad {
      min-height: 224px;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 0 16px;
      width: 100%;
      height: 100%;
    }
  }

  .slick-dots {
    position: absolute;
    left: 15px;
    width: auto !important;
    bottom: 15px;
    color: white;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      margin: 0 3px 0 0;
    }

    li button:before {
      font-size: 12px;
      content: '•';
      text-align: center;
      opacity: .4;
      color: #fff;
    }

    .slick-active button:before {
      opacity: .8;
      color: #fff;
    }
  }

  .internal-ad-title {
    font-size: 2.2rem;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Eurostile';
  }

  .internal-ad-subtitle {
    font-size: 1.125rem;
    font-family: 'Arial', sans-serif;
    opacity: 0.9;
  }

  .internal-ad-cta {
    margin-top: .5rem;
    margin-bottom: 0;
    font-size: 1.1rem;
    font-family: 'Eurostile', sans-serif;
    padding: 0.7rem 2rem;
    font-weight: bold;
    text-transform: uppercase;
    background: #28a0db;
    border-radius: 3px;
  }
}

.placement-blog_main {
  padding-bottom: 1rem;
  .slick-dots {
    bottom: 25px !important; /* Hack, not sure why this is needed */
  }
  .internal-ad-title {
    font-size: 28px;
  }
}

.placement-products {
  margin-bottom: 30px !important;
}

.placement-blog_main, .placement-products, .placement-downloads {
  border-radius: 5px !important;
  .internal-ad, .internal-ad-container {
    border-radius: 5px !important;
  }
  .slick-list {
    border-radius: 5px;
  }
}

@media screen and (max-width: 40em) {
  .placement-products, .placement-cms_pages, .placement-profile, .placement-collection {
    .internal-ad {
      .internal-ad-title {
        font-size: 1.4rem;
        line-height: 1;
      }
  
      .internal-ad-subtitle {
        font-size: 1rem;
      }
    }
  }
}