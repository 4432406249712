.responsive-embed iframe {
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
  font-family: helvetica neue, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  cursor: auto;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  box-sizing: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.eurostile {
  font-family: 'Eurostile', sans-serif;
}

.inter {
  font-family: 'Inter', sans-serif;
}

.responsive-embed,
.flex-video,
.post .content figure:not([data-trix-content-type='image']) {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.post .content figure:not([data-trix-content-type='image']) iframe,
.flex-video object,
.post .content figure:not([data-trix-content-type='image']) object,
.flex-video embed,
.post .content figure:not([data-trix-content-type='image']) embed,
.flex-video video,
.post .content figure:not([data-trix-content-type='image']) video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Cart sidebar overlay */
div.bm-overlay {
  top: 70px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6) !important;
  z-index: 50;
}

/** Don't show icons on any input fields **/
/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.custom .react-tooltip {
  background: #0b1113 !important;
  z-index: 999 !important;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.avfx-tag {
  box-sizing: border-box;
  background: #c7d0d4;
  display: inline-block;
  // height: 18px;
  // width:
  color: #252b2d;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  border-top: 9px solid rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 16.5px;
  span {
    display: block;
    margin-top: -8px;
  }
}

.silver-tag {
  background: #c7d0d4;
  color: #252b2d;
  border-top: 9px solid rgba(255, 255, 255, 0.4);
}

.gold-tag {
  background: #f8c100;
  color: #252b2d;
  border-top: 9px solid rgba(255, 255, 255, 0.4);
}

.elite-tag {
  background: #00a1e0;
  color: white;
  border-top: 9px solid rgba(255, 255, 255, 0.4);
}

.avfxplus-flat {
  background: #4d5457;
  color: #f5f5f5;
  border-radius: 3px;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Inter';
  width: 50px;
  display: inline-block;
  line-height: 20px;
  text-align: center;
}

.pricing-title {
  strong {
    color: #00a1e0;
  }
}

.pricing-subtitle {
  strong {
    color: white;
  }
}

.blue-bold {
  strong {
    color: #00a1e0;
    font-weight: inherit;
  }
}

.text-ellipsis,
.text-ellipsis > div {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* number of lines to show */
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.noscrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.noscrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
