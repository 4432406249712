footer a:hover {
  color: #f5f5f5;
}

/* Active Campaign Signup Form */
._form_1 {
  font-family: 'Inter' !important;

  form {
    background: none !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;

    padding: 0 !important;
  }

  ._form-content * {
    color: #7f898d;
  }

  ._form-label,
  ._form-title {
    color: #f5f5f5 !important;
    font-family: 'Inter' !important;
    font-weight: normal !important;
  }

  ._form-title {
    font-size: 18px !important;
    font-family: 'Eurostile' !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    letter-spacing: 0.02em;
  }

  #_form_1_submit {
    margin-top: 24px !important;
    font-size: 12px !important;
    font-family: 'Inter' !important;
    display: block !important;
    padding-bottom: 8px !important;
    width: 100%;
  }
}
